import createMobxModel from './createMobxModel';

const Organization = createMobxModel({
  name: 'organization',

  fields: [
    '$placeholder',
    'allowLooker',
    'allowPriorityMessage',
    'autoForwardEntitiesIds',
    'autoLogoutMinutes',
    'broadcastListAdSync',
    'canAdminRoles',
    'canEscalateMessages',
    'canShowTransactions',
    'canUploadRolesSchedule',
    'conversationExpiration',
    'deleteOnRead',
    'desktopAutoUpdate',
    'desktopExtendedSession',
    'desktopPriorityMessagingSounds',
    'displayName',
    'dndAutoForward',
    'dndAutoForwardEntities',
    'emojiReactions',
    'featurePbxIntegration',
    'forumsEnabled',
    'hideNewPblUi',
    'isAutomatedAppointmentRemindersEnabled',
    'isContacts',
    'isForwardAllowed',
    'isGroupVideoCallEnabled',
    'isGroupVoiceCallEnabled',
    'isPatientBroadcastEnabled',
    'isPatientContextEnabled',
    'isPatientDeliveryMethodEnabled',
    'isPatientNetworkEnabled',
    'isPatientScheduledMessagesEnabled',
    'isPatientListAccessEnabled',
    'isPatientVirtualWaitingRoomEnabled',
    'isPFAttachmentAllowed',
    'isPFGroupVideoCallEnabled',
    'isPFGroupVoiceCallEnabled',
    'isPFPhotoAllowed',
    'isPFVideoCallEnabled',
    'isPFVirtualWaitingRoomEnabled',
    'isPFVoiceCallEnabled',
    'isVideoCallEnabled',
    'isVoiceCallEnabled',
    'lastIncomingMessageSortNumber',
    'mentions',
    'messageTimeToLive',
    'mtorgEnabled',
    'name',
    'networks',
    'otpAllowConfigure',
    'pendingAdminApproval',
    'personaManagement',
    'pfVideoCallLinkTtl',
    'preserveGroupConversation',
    'rolesEnabled',
    'roleTransition',
    'showAlerts',
    'showPatientCare',
    'showTeams',
    'unreadAlertsCount',
    'unreadCount',
    'unreadPriorityCount',
    'virtualWaitingRoomUnreadCount',
  ],

  relations: {
    many: {
      alertMessages: { type: 'message' },
      allowedSenders: { type: ['role', 'user'] },
      conversations: { type: 'conversation' },
      messageTemplates: { type: 'messageTemplate' },
      roles: { type: 'role' },
      tags: { type: 'tag' },
    },
    one: {
      autoForwardReceiver: { type: 'user' },
      userRoles: { type: 'userRoles' },
    },
  },
});

export default Organization;
