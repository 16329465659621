import {
  Persona,
  PersonaCreateFields,
  PersonaGetAllFields,
  PersonaSearchResponse,
  PersonaUpdateFields,
} from '../types/Persona';
import BaseAPI from './BaseAPI';

export default class PersonaAPI extends BaseAPI {
  async findPersonas({
    organizationId,
    persona_name = '',
    pageSize = 10,
    page = 1,
  }: PersonaGetAllFields): Promise<PersonaSearchResponse> {
    const { data } = (await this.httpClient.get(`${this.host.tcUrl}/v1/user-service/Personas`, {
      headers: {
        'tt-x-organization-key': organizationId,
      },
      query: {
        page,
        pageSize,
        persona_name,
      },
    })) as unknown as { data: PersonaSearchResponse };

    return data;
  }

  async findTitles(organizationId: string) {
    const endpoint = `${this.host.tcUrl}/v1/user-service/Titles/GetOrgTitles`;
    const res = await this.httpClient.get(endpoint, {
      headers: { 'TT-X-Organization-Key': organizationId },
    });
    return res.data;
  }

  async createPersona(payload: PersonaCreateFields, orgId: string): Promise<Persona> {
    const endpoint = `${this.host.tcUrl}/v1/user-service/Personas`;

    try {
      const res = (await this.httpClient.post(endpoint, {
        data: {
          ...payload,
          ...this._formatPermissions(payload.permissions),
        },
        headers: {
          'TT-X-Organization-Key': orgId,
        },
      })) as unknown as Promise<Persona>;
      return res;
    } catch (e) {
      console.error('API - Failed to create persona: ', JSON.stringify(e));
      throw e;
    }
  }

  async updatePersona(payload: PersonaUpdateFields, personaId: string, orgId: string) {
    const endpoint = `${this.host.tcUrl}/v1/user-service/Personas/${personaId}`;

    try {
      const res = (await this.httpClient.put(endpoint, {
        data: {
          ...payload,
          ...this._formatPermissions(payload.permissions),
        },
        headers: {
          'TT-X-Organization-Key': orgId,
        },
      })) as unknown as Promise<Persona>;
      return res;
    } catch (e) {
      console.error('API - Failed to update persona: ', JSON.stringify(e));
      throw e;
    }
  }

  async deletePersona(personaId: string, orgId: string) {
    const endpoint = `${this.host.tcUrl}/v1/user-service/Personas/${personaId}`;

    try {
      const res = (await this.httpClient.del(endpoint, {
        headers: {
          'TT-X-Organization-Key': orgId,
        },
      })) as unknown as Promise<Persona>;
      return res;
    } catch (e) {
      console.error('API - Failed to delete persona: ', JSON.stringify(e));
      throw e;
    }
  }

  _formatPermissions(permissions: { [k: string]: boolean } | undefined) {
    return permissions
      ? {
          permissions: Object.entries(permissions)
            .filter(([_, v]) => v)
            .map(([k]) => k),
        }
      : {};
  }
}
