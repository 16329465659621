import EventEmitter from 'events';
import throttle from 'lodash.throttle';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';
import { noop } from 'node-noop';
import Select from 'react-select-plus';
import Highlighter from 'react-highlight-words';
import { HotKeys } from 'react-hotkeys';
import shallowequal from 'shallowequal';
import * as Sentry from '@sentry/react';

import {
  Team,
  Role,
  Patient,
  PatientContact,
  Individual,
  Group,
  DistributionList,
  Forum,
} from '../../widgets/messenger/components/SearchSidebar/SearchParityResults';

import HotKeyActions from '../../models/enums/HotKeyActions';
import BEM from '../bem';
import { ReactComponent as CloseButton } from '../images/close-button.svg';
import { ReactComponent as MAEnter } from '../images/ma-enter.svg';
import propTypes from '../propTypes';
import { FROZEN_EMPTY_ARRAY, mobxInjectSelect } from '../utils';
import { prepareAllEntries } from '../utils/searchResults';
import {
  DotsIndicator,
  EntityAvatar,
  PatientBroadcastListSearchResultDetails,
  PatientContactSearchResultDetails,
  PatientDetails,
  PatientSearchResultDetails,
  PatientSearchResultHeader,
  TagIndicator,
  UserTitleDepartment,
} from './';
import { PatientReferenceInfo } from 'common/components/PatientContext';

import { validateEntityOrder } from 'common/utils/searchParity';

const classes = BEM.with('RecipientPicker');
const CENTERED_MARGIN_LEFT = -86;
const SEARCH_RESULT = 'SEARCH_RESULT';
const SELECTED_ITEM = 'SELECTED_ITEM';
const AVATAR_SIZES = {
  [SEARCH_RESULT]: 25,
  [SELECTED_ITEM]: 30,
};

const mapLabelToEntity = {
  FORUM: 'Forums',
  Teams: 'team',
  Roles: 'role',
  Individuals: 'user',
  Staff: 'user',
  Groups: 'group',
  Broadcast: 'distributionList',
  'Care Team': 'careTeam',
};

function highlightText(str, searchWords, className) {
  if (!searchWords) searchWords = FROZEN_EMPTY_ARRAY;
  if (!str) return null;

  return (
    <Highlighter
      autoEscape={true}
      className={classes(className)}
      highlightClassName={classes('search-text')}
      searchWords={searchWords}
      textToHighlight={str}
    />
  );
}

class RecipientPicker extends Component {
  static propTypes = {
    ariaDisabled: PropTypes.bool,
    ariaLabelItem: PropTypes.string,
    ariaLabelSelect: PropTypes.string,
    arrowRenderer: PropTypes.func,
    centeredOuterMenu: PropTypes.bool,
    className: PropTypes.string,
    clearSearchResults: PropTypes.func.isRequired,
    currentModalType: PropTypes.string,
    currentOrganization: propTypes.organization,
    currentConversation: propTypes.conversation,
    currentUserId: PropTypes.string,
    dataTestId: PropTypes.string,
    events: PropTypes.instanceOf(EventEmitter).isRequired,
    excludeIds: PropTypes.arrayOf(propTypes.idType),
    hideDnd: PropTypes.bool.isRequired,
    isAutoSized: PropTypes.bool.isRequired,
    isExtendedAutoForwardOptionsEnabled: PropTypes.bool.isRequired,
    isProviderNetwork: PropTypes.bool,
    isSearching: PropTypes.bool.isRequired,
    isSingleRecipient: PropTypes.bool,
    multi: PropTypes.bool,
    onBlur: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    onEsc: PropTypes.func,
    onFocus: PropTypes.func,
    onSearch: PropTypes.func.isRequired,
    onTab: PropTypes.func,
    isOpenOnFocus: PropTypes.bool,
    isUserDndAfEnabledForCurrentOrg: PropTypes.func,
    openOnType: PropTypes.bool,
    isAvailabilityFeatureFlagEnabled: PropTypes.bool,
    isPresenceFeatureFlagEnabled: PropTypes.bool,
    openPlaceholder: PropTypes.string,
    organizationId: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    reposition: PropTypes.bool,
    searchResults: propTypes.searchResultArray.isRequired,
    searchErrorOccurred: PropTypes.bool,
    selected: PropTypes.oneOfType([propTypes.counterPartyArray, PropTypes.object]),
    setSelectedRecipients: PropTypes.func,
    clearRecipients: PropTypes.func,
    showCloseButton: PropTypes.bool,
    showLoadingSpinner: PropTypes.bool,
    shouldValidateSearchResults: PropTypes.bool.isRequired,
    stopCurrentAction: PropTypes.func.isRequired,
    tabIndex: PropTypes.number,
    hotkeyOptions: PropTypes.shape({
      component: PropTypes.string,
      isFlex: PropTypes.bool,
    }),
    isEntityInConversationCheck: PropTypes.bool,
    shouldShowSelectedValues: PropTypes.bool,
    shouldAutoBlur: PropTypes.bool,
    selectedRecipients: propTypes.counterPartyArray,
    accessibilityMode: PropTypes.bool,
    isNoSearchResultsOnEmptyQueryEnabled: PropTypes.bool,
  };

  static defaultProps = {
    multi: false,
    onBlur: noop,
    onFocus: noop,
    isOpenOnFocus: true,
    openOnType: false,
    reposition: true,
    showCloseButton: false,
    showLoadingSpinner: true,
    shouldShowSelectedValues: true,
    shouldAutoBlur: false,
    accessibilityMode: false,
  };

  state = {
    currentMenuHeight: 540,
    currentMenuWidth: 'auto',
    menuContainerStyle: {
      marginLeft: 0,
    },
    isQueryResult: true,
    groupMember: [],
  };

  _lastInput = null;
  _lastSelected = null;
  _searchWords = null;
  _selectedEntries = null;

  constructor(props) {
    super(props);

    const { currentModalType, onEsc, stopCurrentAction } = props;

    this.isAutoForwardModalOpen = currentModalType === 'autoForwardModal';
    this._passedOnEsc = onEsc === undefined ? stopCurrentAction : onEsc;
    this._windowResized = _.throttle(this._windowResized, 50);

    this.handlers = {
      [HotKeyActions.ESCAPE]: this._onEsc,
    };
  }

  componentDidMount() {
    const { openOnType, dataTestId, currentConversation } = this.props;
    if (!openOnType || currentConversation?.counterPartyType !== 'group') {
      this._search('');
    }
    window.addEventListener('resize', this._windowResized);
    if (dataTestId) {
      const input = ReactDOM.findDOMNode(this.select.input);
      input?.setAttribute('data-test-id', dataTestId);
    }
  }

  componentWillUnmount() {
    const { openOnType } = this.props;
    this._windowResized.cancel();
    window.removeEventListener('resize', this._windowResized);
    if (openOnType) {
      const input = ReactDOM.findDOMNode(this.select);
      input?.removeEventListener('mousedown', this._bypassClick);
    }
  }

  getSnapshotBeforeUpdate(prevProps) {
    const { isSearching, input } = this.props;
    if (isSearching === true && prevProps.isSearching === false) {
      if (this.select && this.select.menu) {
        const height = this.select.menu.offsetHeight;
        const width = this.select.menu.offsetWidth;
        this.setState({
          currentMenuHeight: input === '' ? 540 : height,
          currentMenuWidth: width,
        });
      }
    }

    return null;
  }

  componentDidUpdate() {
    this._reposition();
  }

  render() {
    const {
      ariaDisabled,
      ariaLabelSelect,
      autoFocus = true,
      className,
      excludeIds,
      isAutoSized,
      isProviderNetwork,
      isSearching,
      isSingleRecipient,
      input,
      multi,
      isOpenOnFocus,
      openOnType,
      openPlaceholder,
      organizationId,
      reposition,
      showCloseButton,
      tabIndex,
      shouldShowSelectedValues,
      shouldAutoBlur,
      accessibilityMode,
    } = this.props;
    let { arrowRenderer, selected, searchResults, placeholder } = this.props;
    let { menuContainerStyle } = this.state;
    const { currentMenuHeight, currentMenuWidth } = this.state;
    let scheduleMessageRecipientAdded;
    let isBroadcastList, isUser;

    if (!arrowRenderer && showCloseButton) arrowRenderer = this._renderCloseButton;
    if (!reposition) menuContainerStyle = null;

    if (!multi) {
      selected = selected ? [selected] : [];
    }
    selected = selected.slice();
    searchResults = searchResults.filter(({ entity }) => !excludeIds.includes(entity.id));

    if (isSingleRecipient && selected?.length > 0) {
      isBroadcastList = selected[0].$entityType === 'distributionList';
      isUser = selected[0].$entityType === 'user';
      scheduleMessageRecipientAdded = true;
    }

    if (!this._selectedEntries || !shallowequal(selected, this._lastSelected)) {
      this._selectedEntries = prepareAllEntries(selected, { organizationId });
    }

    const hideOverflow = this._selectedEntries.length === 0;

    if (searchResults.length === 1 && searchResults[0].messageAnyoneRecipient) {
      this._searchWords = null;
    } else if (input !== this._lastInput) {
      this._searchWords = input.split(',').map((word) => word.trim());
    }

    if (this.isOpen() && openPlaceholder) {
      placeholder = openPlaceholder;
    }

    const cssClass =
      !isProviderNetwork && (selected.length > 1 || selected.length === 1)
        ? 'multiRec'
        : 'singleRec';

    this._lastInput = input;
    this._lastSelected = selected;
    const disableSearch = this._searchDisabled();

    let value = null;
    if (shouldShowSelectedValues) {
      if (multi) {
        value = this._selectedEntries;
      } else {
        value = this._selectedEntries && this._selectedEntries[0] ? this._selectedEntries[0] : null;
      }
    }

    const srpDisplayLoading = !disableSearch && isSearching && this.state.isQueryResult;
    const nonDisabledUserTabIndex = typeof tabIndex !== 'undefined' ? String(tabIndex) : null;
    const selectTabIndex = accessibilityMode ? '0' : nonDisabledUserTabIndex;

    return (
      <HotKeys
        aria-label={ariaLabelSelect}
        className={classes({
          hideOverflow,
          isFlex: this.hotkeyOptions.isFlex,
          isSearching,
          isSearchingText: isSearching,
          searchParityProviderNetwork: isProviderNetwork,
          searchParityPatientNetwork: !isProviderNetwork,
          searchParity: true,
        })}
        component={this.hotkeyOptions.component}
        handlers={this.handlers}
        aria-disabled={ariaDisabled}
      >
        <Select
          autoBlur={shouldAutoBlur}
          allowCreate
          arrowRenderer={arrowRenderer}
          autoFocus={autoFocus}
          autosize={isAutoSized}
          className={classNames(
            className,
            classes(className, {
              scheduleMessageRecipientAdded,
              isBroadcastList,
              isUser,
            }),
            classes(cssClass)
          )}
          menuStyle={
            srpDisplayLoading
              ? {
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: currentMenuHeight,
                  width: currentMenuWidth,
                }
              : {}
          }
          escapeClearsValue={false}
          filterOption={this._allowAll}
          isLoading={false}
          menuContainerStyle={menuContainerStyle}
          multi={multi}
          noResultsText={this._determineNoResultsText()}
          onBlur={this._onBlur}
          onOpen={this._onOpen}
          onChange={this._onChange}
          onClose={this._onClose}
          onFocus={this._onFocus}
          onInputChange={this._onInputChange}
          onInputKeyDown={this._onInputKeyDown}
          openOnFocus={openOnType ? false : ariaDisabled ? false : isOpenOnFocus}
          openOnClick={!openOnType}
          optionRenderer={srpDisplayLoading ? this._searchingText : this._renderSearchParityResult}
          options={this._buildOptions()}
          placeholder={placeholder}
          ref={this._setSelect}
          searchable={!disableSearch}
          tabIndex={selectTabIndex}
          value={value}
          valueRenderer={this._renderSelectedItem}
        />
      </HotKeys>
    );
  }

  _determineNoResultsText = () => {
    if (this.props.isNoSearchResultsOnEmptyQueryEnabled && this._lastInput === '') return null;
    const { searchErrorOccurred } = this.props;
    return searchErrorOccurred ? 'Search unavailable, please try again' : 'No results found';
  };

  _mapEntityToLabel = (currentEntity, isProviderNetwork) => {
    const { groupType: group, $entityType: entity, isPatient, isPatientContact } = currentEntity;
    if (group === 'FORUM') return 'Forums';
    if (entity === 'team') return 'Teams';
    if (entity === 'role') return 'Roles';
    if (entity === 'careTeam') return 'Care Team';
    if (
      (entity === 'user' && isProviderNetwork) ||
      (!isProviderNetwork && (isPatient || isPatientContact))
    )
      return 'Individuals';
    if (entity === 'user' && !isProviderNetwork) return 'Staff';
    if (entity === 'group') return 'Groups';
    if (entity === 'distributionList') return 'Broadcast';
  };

  _buildOptions = () => {
    if (this.props.isNoSearchResultsOnEmptyQueryEnabled && this._lastInput === '') return;
    const {
      currentOrganization,
      currentUserId,
      entitySortMap,
      shouldValidateSearchResults,
      isExtendedAutoForwardOptionsEnabled,
      isProviderNetwork,
      isSearching,
      currentConversation,
    } = this.props;

    if (
      currentConversation?.counterPartyType === 'group' &&
      this.select?.state?.inputValue === '' &&
      !isSearching
    ) {
      const membersByType = _.groupBy(currentConversation?.counterParty?.members, '$entityType');
      const typeToLabel = (type: string) =>
        type === 'user' ? 'Individuals' : type === 'role' ? 'Roles' : type;

      return Object.entries(membersByType).map(([type, options]) => ({
        label: typeToLabel(type),
        options: options.map((item) => ({ entity: item, value: item.id })),
      }));
    }

    if (isSearching && this.state.isQueryResult) {
      return [{ value: 'loading', label: 'loading' }];
    } else {
      const grouped = this.props.searchResults.reduce((all, current) => {
        const labels = all.map((v) => v.label);
        const label = this._mapEntityToLabel(current.entity, isProviderNetwork);

        if (this.isAutoForwardModalOpen && current.entity.dnd) {
          current.disabled = !(
            isExtendedAutoForwardOptionsEnabled && current.entity.id === currentUserId
          );
        }

        const index = labels.indexOf(label);
        if (index !== -1) {
          all[index].options.push(current);
        } else {
          all.push({ label, options: [current] });
        }
        return all;
      }, []);

      if (
        currentOrganization?.isContacts &&
        grouped.length > 1 &&
        grouped[0].label !== 'Individuals'
      ) {
        const tmp = grouped[0];
        grouped[0] = grouped[1];
        grouped[1] = tmp;
      } else {
        grouped.sort((x, y) => {
          const xValue = entitySortMap.get(mapLabelToEntity[x.label]);
          const yValue = entitySortMap.get(mapLabelToEntity[y.label]);
          if (xValue < yValue) return -1;
          if (xValue > yValue) return 1;
          return 0;
        });
      }

      // pagination loading dots
      if (isSearching && !this.state.isQueryResult) {
        grouped.push({ label: 'loading', options: [{ entity: 'loading' }] });
      }

      if (shouldValidateSearchResults) {
        const entityTypes = grouped.map((entity) => entity.entity_type);
        const isValid = validateEntityOrder(entityTypes);
        if (!isValid) {
          const e = new Error(
            `RecipientPicker Invalid entity order; entityTypes: ${entityTypes}; entitySortMap: ${Array.from(
              entitySortMap.entries()
            )}`
          );
          Sentry.captureException(e);
        }
      }

      return grouped;
    }
  };

  _allowAll = () => true;

  _setSelect = (ref) => {
    const { openOnType } = this.props;
    this.select = ref;
    // eslint-disable-next-line react/no-find-dom-node
    if (openOnType) {
      const input = ReactDOM.findDOMNode(this.select);
      input?.addEventListener('mousedown', this._bypassClick);
    }
  };

  _bypassClick = (event) => {
    // eslint-disable-next-line react/no-find-dom-node
    const menu = ReactDOM.findDOMNode(this.select.menu);
    if (
      menu?.contains(event.target) ||
      event.target.className === 'Select-value-icon' ||
      (event.target.type === 'text' && event.target.value !== '')
    ) {
      return;
    }
    event.stopPropagation();
    event.preventDefault();
    this.select.focus();
  };

  _onInputChange = (newInput) => {
    const { openOnType } = this.props;
    if (this._searchDisabled()) return;

    if (!this.state.isQueryResult) {
      this.setState({
        isQueryResult: true,
      });
    }

    if (newInput !== this._lastInput) {
      if (openOnType && newInput === '') {
      } else {
        this._search(newInput);
      }
    }

    return newInput;
  };

  _onScroll = throttle((event) => {
    const { clientHeight, scrollHeight, scrollTop } = event.target;
    if (clientHeight / (scrollHeight - scrollTop) > 0.6) {
      this.setState({
        isQueryResult: false,
      });
      this.props.scrollSearch();
    }
  }, 800);

  _onFocus = () => {
    if (this._searchDisabled()) return;
    this.props.onFocus();
  };

  _onBlur = () => {
    this.props.onBlur();
  };

  _onInputKeyDown = (event) => {
    const { openOnType, isSearching, ariaDisabled } = this.props;
    event.stopPropagation();
    const { altKey, ctrlKey, keyCode, metaKey, shiftKey } = event;
    const anyModifier = altKey || ctrlKey || metaKey || shiftKey;
    const ctrlKeyOnly = ctrlKey && !altKey && !metaKey && !shiftKey;
    if (keyCode === 8) {
      if (openOnType && this._lastInput.length === 1) {
        this.select.closeMenu();
      }
      return;
    } else if (keyCode === 13) {
      if (isSearching || ariaDisabled) {
        event.preventDefault();
        event.stopPropagation();
      }
      return;
    } else if (keyCode === 32) {
      if (ariaDisabled) {
        event.preventDefault();
        event.stopPropagation();
      }
      return;
    } else if (keyCode === 9 && !anyModifier) {
      // TAB
      this._onTab(event);
    } else if (keyCode === 27 && !anyModifier) {
      // ESC
      this._onEsc(event);
    } else if (keyCode === 78 && ctrlKeyOnly) {
      // Ctrl+n
      this._menuDown(event);
    } else if (keyCode === 80 && ctrlKeyOnly) {
      // Ctrl+p
      this._menuUp(event);
    }
  };

  _onEsc = (event) => {
    let wasHandled = false;

    if (this.isOpen()) {
      wasHandled = true;
      this.select.closeMenu();
    } else if (this._selectedEntries && this._selectedEntries.length > 0) {
      wasHandled = true;
      this.select.clearValue(event);
    }

    if (wasHandled || !this._passedOnEsc) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      this._passedOnEsc(event);
    }
  };

  _onTab = (event) => {
    const { onTab } = this.props;
    let wasHandled = false;

    if (this.isOpen()) {
      wasHandled = true;
      if (this.select?.state?.focusedOption) {
        this.select.selectFocusedOption();
      } else {
        this.select.closeMenu();
        return;
      }
    }

    if (wasHandled) {
      if (!this.props.accessibilityMode) {
        event.preventDefault();
        event.stopPropagation();
      }
    } else {
      onTab && !this.props.accessibilityMode && onTab(event);
    }
  };

  _menuDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.select.focusNextOption();
  };

  _menuUp = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.select.focusPreviousOption();
  };

  _search(text) {
    this.props.onSearch(text.trim());
  }

  _onChange = (values) => {
    this.select?.closeMenu();

    if (this.props.multi) {
      values = values.filter((v) => v.entity);
      values = (values || FROZEN_EMPTY_ARRAY).map(({ entity }) => entity);

      const shouldClearSelectedRecipients =
        (this._selectedEntries[0]?.entity.isPatient ||
          this._selectedEntries[0]?.entity.isPatientContact) &&
        this._selectedEntries[0]?.entity.id !== values[0]?.id;

      if (shouldClearSelectedRecipients) {
        values = FROZEN_EMPTY_ARRAY;
      }
    } else {
      if (!values) return;
      values = values.entity;
    }

    this.props.onChange(values);
  };

  _onOpen = () => {
    this.select?.menu?.addEventListener('scroll', this._onScroll);
  };

  _onClose = () => {
    this.select?.menu?.removeEventListener('scroll', this._onScroll);
  };

  _renderCloseButton = ({ isOpen }) => {
    if (!isOpen) {
      return null;
    }

    return <CloseButton onClick={this.select?.closeMenu} className={classes('close-button')} />;
  };

  _renderSelectedItem = (item) => {
    return this._renderItem(item, SELECTED_ITEM);
  };

  _renderSearchResult = (item) => {
    return this._renderItem(item, SEARCH_RESULT);
  };

  _renderItem = (item, context) => {
    const { currentUserId, isExtendedAutoForwardOptionsEnabled } = this.props;
    const { entity, organizationId } = item;
    if (!entity) return item.label;

    const {
      $entityType: entityType,
      displayName,
      groupType,
      messageAnyoneRecipient,
      patientDetails,
      patientContextId,
      patientContextDetails,
    } = entity;
    const { ariaLabelItem, isSingleRecipient } = this.props;
    const avatarSize = AVATAR_SIZES[context];
    const searchTerm = context === SEARCH_RESULT ? this._searchWords : null;
    let entityAvatarFragment, patientDetailsFragment, tagFragment, userDetailsFragment;

    if (messageAnyoneRecipient && context === SEARCH_RESULT) {
      return (
        <div className={classes('ma-details')}>
          <span className={classes('ma-header')}>Send encrypted messages to:</span>
          <span className={classes('ma-recipient')}>{displayName}</span>
          <div className={classes('ma-enter')}>
            <span>Press enter</span>
            <MAEnter />
          </div>
        </div>
      );
    }

    if (this.isAutoForwardModalOpen && entity.dnd) {
      const isEntityDisabled = !(
        isExtendedAutoForwardOptionsEnabled && entity.id === currentUserId
      );
      entity.isDisabled = isEntityDisabled;
      item.disabled = isEntityDisabled;
    } else {
      delete entity.isDisabled;
      delete item.disabled;
    }

    if (entityType === 'user') {
      if ((entity.isPatient || entity.isPatientContact) && context === SELECTED_ITEM) {
        return (
          <PatientSearchResultHeader
            entity={entity}
            isSingleRecipient={isSingleRecipient}
            avatarSize={30}
          />
        );
      } else if (entity.isPatient) {
        const input = searchTerm ? searchTerm.join(',') : '';

        return <PatientSearchResultDetails entity={entity} input={input} />;
      } else if (entity.isPatientContact) {
        return (
          <PatientContactSearchResultDetails
            entity={entity}
            appendedResults={this._selectedEntries.length}
          />
        );
      }

      entityAvatarFragment = (
        <EntityAvatar
          entity={entity}
          isSearch={true}
          inverse
          showPresenceIndicator={true}
          size={avatarSize}
          indicatorSize={'SMALL'}
        />
      );
      if (context === SEARCH_RESULT) {
        userDetailsFragment = (
          <UserTitleDepartment
            className={classes('title-department')}
            organizationId={organizationId}
            searchWords={searchTerm}
            user={entity}
          />
        );
      }
    } else if (entityType === 'distributionList' && entity.network === 'patient') {
      return (
        <PatientBroadcastListSearchResultDetails
          context={context}
          entity={entity}
          searchTerms={searchTerm}
        />
      );
    } else if (entityType === 'role') {
      entityAvatarFragment = <EntityAvatar entity={entity} inverse size={avatarSize} />;
    } else {
      entityAvatarFragment = <EntityAvatar entity={entity} size={avatarSize} />;
    }

    if (['role', 'team', 'group'].includes(entityType) && context === SEARCH_RESULT) {
      const { tag } = entity;

      if (tag && !tag.isDefault) {
        tagFragment = <TagIndicator classes={classes} tag={tag} searchWords={searchTerm} />;
      }
    }

    if (entityType === 'group' && groupType === 'PATIENT_CARE' && context === SEARCH_RESULT) {
      patientDetailsFragment = (
        <PatientDetails patient={patientDetails} className={classes('patient-details')} />
      );
    }

    const patientContextFragment = entityType === 'group' && patientContextId && (
      <PatientReferenceInfo
        patientReferenceId={patientContextId}
        patientReference={patientContextDetails}
        type={'pill'}
      />
    );

    return (
      <div
        aria-label={ariaLabelItem}
        className={classes('user-details', {
          isFamilyAndContactsMessagingClass:
            this._selectedEntries[0]?.entity.isPatient ||
            this._selectedEntries[0]?.entity.isPatientContact,
          isDisabled: item.disabled,
        })}
      >
        {entityAvatarFragment}
        <div>
          {highlightText(entity.displayName, searchTerm, 'entity-name')}
          {patientContextFragment}
        </div>
        {userDetailsFragment}
        {tagFragment}
        {patientDetailsFragment}
      </div>
    );
  };

  _searchingText = () => {
    const { isEntityInConversationCheck } = this.props;
    return (
      <div
        style={{
          textAlign: isEntityInConversationCheck ? 'center' : 'left',
          color: 'var(--neutral-600)',
          backgroundColor: 'var(--neutral-100)',
          fontSize: 16,
        }}
        className={classes('searching-text')}
      >
        Searching...
      </div>
    );
  };

  _renderSearchParityResult = (item) => {
    const {
      currentConversation,
      currentUserId,
      hideDnd,
      isAvailabilityFeatureFlagEnabled,
      isExtendedAutoForwardOptionsEnabled,
      isUserDndAfEnabledForCurrentOrg,
      isPresenceFeatureFlagEnabled,
      subscribeUser,
      unsubscribeUser,
      isEntityInConversationCheck,
      selectedRecipients,
    } = this.props;
    const { entity } = item;
    if (item.label === 'loading') {
      return (
        <div className={classes('loading')}>
          <DotsIndicator size={13} speed={0.5} />
        </div>
      );
    }
    if (!entity) {
      return <div className={classes('label')}>{item.label}</div>;
    }
    const { $entityType: entityType, displayName, groupType, messageAnyoneRecipient } = entity;

    if (messageAnyoneRecipient) {
      return (
        <div className={classes('ma-details')}>
          <span className={classes('ma-header')}>Send encrypted messages to:</span>
          <span className={classes('ma-recipient')}>{displayName}</span>
          <div className={classes('ma-enter')}>
            <span>Press enter</span>
            <MAEnter />
          </div>
        </div>
      );
    }

    if (entityType === 'user' || entityType === 'careTeam') {
      const userDndAfStatusForCurrentOrg = isUserDndAfEnabledForCurrentOrg(entity);

      if (entity.isPatient) {
        return <Patient patient={entity} />;
      } else if (entity.isPatientContact) {
        return <PatientContact patientContact={entity} />;
      }
      if (this.isAutoForwardModalOpen && entity.dnd) {
        entity.disabled = !(isExtendedAutoForwardOptionsEnabled && entity.id === currentUserId);
      }
      return (
        <div className={classes('item-wrapper')}>
          <Individual
            currentOrganization={this.props.currentOrganization}
            currentUserId={currentUserId}
            hideDnd={hideDnd}
            individual={entity}
            isDisabled={entity.disabled}
            isPresenceFeatureFlagEnabled={isPresenceFeatureFlagEnabled}
            isAvailabilityFeatureFlagEnabled={isAvailabilityFeatureFlagEnabled}
            showDndAfStatus={userDndAfStatusForCurrentOrg}
            showPresenceIndicator={true}
            presenceSize={'small'}
            typeOfUser={'composeUser'}
            currentConversation={currentConversation}
            isEntityInConversationCheck={isEntityInConversationCheck}
            selectedRecipients={selectedRecipients}
          />
        </div>
      );
    } else if (entityType === 'distributionList' && entity.network === 'patient') {
      return <DistributionList distributionList={entity} />;
    } else if (entityType === 'role') {
      return (
        <div className={classes('item-wrapper')}>
          <Role
            role={entity}
            isAvailabilityFeatureFlagEnabled={isAvailabilityFeatureFlagEnabled}
            subscribeUser={subscribeUser}
            unsubscribeUser={unsubscribeUser}
            currentConversation={currentConversation}
            isEntityInConversationCheck={isEntityInConversationCheck}
            selectedRecipients={selectedRecipients}
          />
        </div>
      );
    } else if (entityType === 'team') {
      return <Team team={entity} />;
    } else if (entityType === 'group') {
      if (groupType === 'GROUP' || groupType === 'ACTIVATED_TEAM') return <Group group={entity} />;
      if (groupType === 'FORUM') return <Forum forum={entity} />;
    } else if (entityType === 'distributionList') {
      return <DistributionList distributionList={entity} />;
    } else {
      return item.label;
    }
  };

  _reposition = () => {
    const { centeredOuterMenu } = this.props;
    const { menuContainerStyle } = this.state;
    const { marginLeft } = menuContainerStyle;

    if (!centeredOuterMenu) {
      // eslint-disable-next-line react/no-find-dom-node
      const input = ReactDOM.findDOMNode(this.select.input);
      const inputLeft = input.getBoundingClientRect().left;
      const parentLeft = input.parentElement.getBoundingClientRect().left;
      const marginLeft = inputLeft - parentLeft - 0;

      if (Math.abs(marginLeft - menuContainerStyle.marginLeft) >= 1) {
        this.setState({ menuContainerStyle: { marginLeft } });
      }
    } else if (centeredOuterMenu && marginLeft !== CENTERED_MARGIN_LEFT) {
      const marginLeft = CENTERED_MARGIN_LEFT;
      this.setState({ menuContainerStyle: { marginLeft } });
    }
  };

  _searchDisabled = () => {
    if (this.props.ariaDisabled) return true;
    const entity = this._lastSelected[0];

    return !!(entity && entity.$entityType === 'distributionList');
  };

  _windowResized = () => {
    this._reposition();
  };

  isOpen() {
    return this.select ? this.select.state.isOpen : false;
  }

  get hotkeyOptions() {
    const { component, isFlex } = this.props.hotkeyOptions || {};
    return {
      component: component !== undefined ? component : 'span',
      isFlex: isFlex !== undefined ? isFlex : true,
    };
  }
}

export default mobxInjectSelect({
  messengerStore: [
    'currentOrganization',
    'isAvailabilityFeatureFlagEnabled',
    'isExtendedAutoForwardOptionsEnabled',
    'isPresenceFeatureFlagEnabled',
    'shouldValidateSearchResults',
    'isNoSearchResultsOnEmptyQueryEnabled',
  ],
  modalStore: ['currentModalType'],
  networkStore: ['isProviderNetwork'],
  recipientPickerStore: ['entitySortMap', 'clearSearchResults', 'scrollSearch'],
  presenceStore: ['subscribeUser', 'unsubscribeUser'],
  sessionStore: ['currentUserId', 'accessibilityMode'],
  userStore: ['hideDnd', 'isUserDndAfEnabledForCurrentOrg'],
  conversationStore: ['currentConversation'],
  composeMessageStore: ['selectedRecipients'],
})(RecipientPicker);
